<template>
    <!-- Header -->
    <header id="header" class="bg-gray-700">
        <nav class="container mx-auto flex justify-start items-center py-5 px-4">
          <!-- App Name -->
          <router-link class="text-white font-bold uppercase text-2xl mr-4"
            :to="{ name: 'home' }" exact-active-class="no-active">
            Music
          </router-link>

          <div class="flex flex-grow items-center">
            <!-- Primary Navigation -->
            <ul class="flex flex-row mt-1">
              <!-- Navigation Links -->
              <li>
                <router-link class="px-2 text-white" :to="{ name: 'about' }">
                  About
                </router-link>
              </li>
              <li v-if="!userLoggedIn" >
                <a class="px-2 text-white" href="#" @click.prevent="toggleAuthModal">
                  Login / Register
                  </a>
              </li>
              <template v-else>
                <li>
                  <router-link class="px-2 text-white" :to="{ name: 'manage' }">
                    Manage
                  </router-link>
                </li>
                <li>
                  <a class="px-2 text-white" href="#"
                  @click.prevent="signout">Logout</a>
                </li>
              </template>
            </ul>
            <ul class="flex flex-row mt-1 ml-auto">
              <li>
                <select class="px-2 text-white bg-transparent" v-model="$i18n.locale">
                  <option v-for="(locale, i) in locales" :key="`Locale${i}`" :value="locale">
                    {{ locale }}
                  </option>
                </select>
              </li>
            </ul>
          </div>
        </nav>
    </header>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
      locales: ['en', 'fr'],
    };
  },
  computed: {
    ...mapState({
      userLoggedIn: (state) => state.auth.userLoggedIn,
    }),
  },
  methods: {
    ...mapMutations(['toggleAuthModal']),
    signout() {
      this.$store.dispatch('signout');

      // console.log(this.$route);
      if (this.$route.meta.requiresAuth) {
        this.$router.push({ name: 'home' });
      }
    },
    // signout() {
    //   this.$store.dispatch('signout');   <-- this function does the same thing as the one above
    // },
    // toggleAuthModal() {
    //   this.$store.commit('toggleAuthModal');
    // },
  },
};
</script>
