export default {
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an about page"])}
  },
  "auth": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Account"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
  },
  "home": {
    "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to Great Music!"])}
  },
  "manage": {
    "my_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My songs"])}
  },
  "register": {
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "accept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I accept Music's ", _interpolate(_list(0))])}
  },
  "song": {
    "comment_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No Comments"]), _normalize(["1 Comment"]), _normalize([_interpolate(_named("count")), " Comments"])])}
  },
  "upload": {
    "dropbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop your files here"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
  }
}