export default {
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci est une page à propos"])}
  },
  "auth": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Votre compte"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])}
  },
  "home": {
    "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écoutez de la bonne musique!"])}
  },
  "manage": {
    "my_songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes chansons"])}
  },
  "register": {
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "upload": {
    "dropbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposez vos fichiers ici"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])}
  }
}